import { Injectable } from '@angular/core';
import {
  Observable,
  Subject,
  map,
  tap,
  catchError,
  of,
  BehaviorSubject,
  switchMap,
  finalize,
  shareReplay,
} from 'rxjs';
import { Vehicle } from 'src/app/shared/models/vehicle/vehicle';
import { VehicleUsage } from 'src/app/shared/models/vehicle-usages/vehicle-usages';
import { VehicleUsageResponse } from 'src/app/shared/models/vehicle-usages/vehicle-usages-response';
import { TenantService } from 'src/app/shared/api-services/tenant.service';
import { VehicleUsagesService } from 'src/app/shared/api-services/vehicle-usages.service';
import { VehiclesService } from 'src/app/shared/api-services/vehicles.service';
import { VehiclesFilter } from 'src/app/shared/models/vehicle/vehicle-filter';
import { Track } from 'src/app/shared/models/track/track';
import { TrackFilter } from 'src/app/shared/models/track/track-filter';
import { TrackResponse } from 'src/app/shared/models/track/track-response';
import { VehicleResponse } from 'src/app/shared/models/vehicle/vehicle-response';
import { VehicleUsagesFilter } from 'src/app/shared/models/vehicle-usages/vehicle-usage-filter';
import { Trip } from 'src/app/shared/models/trips/trips';
import { TripFilter } from 'src/app/shared/models/trips/trip-filter';
import { TripResponse } from 'src/app/shared/models/trips/trip-response';
import { TripWithTracks } from 'src/app/shared/models/trips/trip-with-tracks';
import { TrackersService } from 'src/app/shared/api-services/tracker.service';
import { DateUtilsService } from 'src/app/shared/utilities/date-utils.service';

@Injectable({
  providedIn: 'root',
})
export class FleetlocationService {
  tenantId = this._tenantService.getTenantId();

  vehicles$: Subject<VehicleResponse> = new Subject<VehicleResponse>();
  vehicle$: BehaviorSubject<Vehicle> = new BehaviorSubject<Vehicle>(null);
  tracks$: BehaviorSubject<Track[]> = new BehaviorSubject<Track[]>([]);
  trips$: BehaviorSubject<Trip[]> = new BehaviorSubject<Trip[]>([]);

  // Loading state
  private isLoadingTracksSubject = new BehaviorSubject<boolean>(false);
  public isLoadingTracks$ = this.isLoadingTracksSubject.asObservable();

  // Add new subject for trips with tracks
  private tripsWithTracksSubject = new BehaviorSubject<TripWithTracks[]>([]);
  public tripsWithTracks$ = this.tripsWithTracksSubject.asObservable();

  private selectedTripSubjet = new BehaviorSubject<Trip>(undefined);
  public selectedTrip$ = this.selectedTripSubjet.asObservable();

  // Selected date range
  private startDate: string | null = null;
  private endDate: string | null = null;

  constructor(
    private _tenantService: TenantService,
    private _vehiclesService: VehiclesService,
    private _vehiclesUsageService: VehicleUsagesService,
    private _trackersService: TrackersService,
    private _dateUtilityService: DateUtilsService
  ) {}

  getVehicles$(
    vehicleFilter: VehiclesFilter = {}
  ): Observable<VehicleResponse> {
    let params: VehiclesFilter = {
      ...vehicleFilter,
      start: 0,
      limit: 10,
      orderBy: '-Id',
      includeMake: true,
      includeModel: true,
      includeVersion: true,
      includeVehicleType: true,
      includePurchaseType: true,
      includeHeadquarter: true,
      includeLocation: true,
      hasPosition: true,
    };

    return this._vehiclesService.listVehicles$(this.tenantId, params).pipe(
      tap((response: VehicleResponse) => {
        this.vehicles$.next(response);
      })
    );
  }

  getVehicleUsage$(vehicleId: string): Observable<VehicleUsage> {
    let params: VehicleUsagesFilter = {
      vehicleId,
      includeUser: true,
      limit: 1,
      orderBy: '-Id',
    };
    return this._vehiclesUsageService
      .listVehicleUsages$(this.tenantId, params)
      .pipe(
        map((response: VehicleUsageResponse) => {
          if (response && response.items && response.items.length > 0) {
            return response.items[0];
          }
          return null;
        })
      );
  }

  setDateRange(startDate?: string, endDate?: string): void {
    this.startDate = startDate || null;
    this.endDate = endDate || null;
  }

  listVehicleTracks$(
    vehicleId: string,
    startDate?: string,
    endDate?: string
  ): Observable<Track[]> {
    this.isLoadingTracksSubject.next(true);

    let params: TrackFilter = {
      posTimeStartRange: startDate ?? this.startDate ?? null,
      posTimeEndRange: endDate ?? this.endDate ?? null,
      start: 0,
      limit: 500,
      orderBy: 'PosTime', // Ordina per orario crescente per visualizzazione sequenziale
    };

    return this._trackersService
      .listVehicleTracks$(this.tenantId, vehicleId, params)
      .pipe(
        map((response: TrackResponse) => response.items),
        tap((tracks: Track[]) => {
          this.sendTracks(tracks);
        }),
        finalize(() => {
          this.isLoadingTracksSubject.next(false);
        }),
        catchError((error) => {
          console.error('Error loading tracks:', error);
          this.isLoadingTracksSubject.next(false);
          return of([]);
        })
      );
  }

  listVehicleTrips$(
    vehicleId: string,
    startDate?: string,
    endDate?: string
  ): Observable<Trip[]> {
    console.log(
      `Loading trips for vehicle ${vehicleId} from ${
        startDate || 'default'
      } to ${endDate || 'default'}`
    );

    this.isLoadingTracksSubject.next(true);
    this.selectedTripSubjet.next(undefined);
    const startDateStr = startDate ?? this.startDate ?? null;
    const endDateStr = endDate ?? this.endDate ?? null;
    const rangeStart = startDateStr
      ? this._dateUtilityService.startOfDay(
          this._dateUtilityService.parseDateFromString(startDateStr)
        )
      : null;
    const rangeEnd = endDateStr
      ? this._dateUtilityService.endOfDay(
          this._dateUtilityService.parseDateFromString(endDateStr)
        )
      : null;
    const params: TripFilter = {
      vehicleId: vehicleId,
      startTime: rangeStart?.toISOString(),
      endTime: rangeEnd?.toISOString(),
      start: 0,
      limit: 100,
      orderBy: '-StartTime',
    };

    return this._trackersService
      .listVehicleTrips$(this.tenantId, vehicleId, params)
      .pipe(
        map((response: TripResponse) => {
          console.log(
            `Received ${response?.items?.length || 0} trips from API`
          );
          return response.items;
        }),
        tap((trips: Trip[]) => {
          console.log(`Emitting ${trips?.length || 0} trips to subscribers`);
          this.trips$.next(trips || []);
          if (trips && trips.length > 0) {
            this.loadTracksForTrips(vehicleId, trips).subscribe();
          }
        }),
        catchError((error) => {
          console.error('Error loading trips:', error);
          this.trips$.next([]);
          this.isLoadingTracksSubject.next(false);
          return of([]);
        }),
        finalize(() => {
          this.isLoadingTracksSubject.next(false);
        })
      );
  }

  loadTracksForTrips(
    vehicleId: string,
    trips: Trip[]
  ): Observable<TripWithTracks[]> {
    if (!trips || trips.length === 0) {
      this.tripsWithTracksSubject.next([]);
      return of([]);
    }

    this.isLoadingTracksSubject.next(true);

    const minDate = trips.reduce((min, trip) => {
      const startTime = trip.startTime ? new Date(trip.startTime) : null;
      return startTime && (!min || startTime < min) ? startTime : min;
    }, null as Date | null);

    const maxDate = trips.reduce((max, trip) => {
      const endTime = trip.endTime ? new Date(trip.endTime) : null;
      return endTime && (!max || endTime > max) ? endTime : max;
    }, null as Date | null);

    if (!minDate || !maxDate) {
      this.tripsWithTracksSubject.next([]);
      this.isLoadingTracksSubject.next(false);
      return of([]);
    }

    return this.listVehicleTracks$(
      vehicleId,
      minDate.toISOString(),
      maxDate.toISOString()
    ).pipe(
      map((tracks) => {
        const tripsWithTracks = trips.map((trip) => {
          const tripStartTime = trip.startTime
            ? new Date(trip.startTime).getTime()
            : 0;
          const tripEndTime = trip.endTime
            ? new Date(trip.endTime).getTime()
            : Infinity;

          const tripTracks = tracks.filter((track) => {
            const trackTime = track.posTime
              ? new Date(track.posTime).getTime()
              : 0;
            return trackTime >= tripStartTime && trackTime <= tripEndTime;
          });

          return { trip, tracks: tripTracks };
        });

        return tripsWithTracks;
      }),
      tap((tripsWithTracks) => {
        this.tripsWithTracksSubject.next(tripsWithTracks);
      }),
      finalize(() => {
        this.isLoadingTracksSubject.next(false);
      }),
      catchError((error) => {
        console.error('Error processing trips with tracks:', error);
        this.isLoadingTracksSubject.next(false);
        return of([]);
      })
    );
  }

  sendTracks(tracks: Track[] | null): void {
    if (tracks) {
      this.tracks$.next(tracks);
    } else {
      this.tracks$.next([]);
    }
  }

  sendVehicle(vehicle: Vehicle | null): void {
    if (!vehicle) return;
    this.vehicle$.next(vehicle);
    this.trips$.next([]);
    this.tracks$.next([]);

    // Single point of entry for loading trips
    this.loadTripsForVehicle(vehicle.id);
  }

  sendTrip(trip: Trip) {
    this.selectedTripSubjet.next(trip);
  }

  private loadTripsForVehicle(vehicleId: string): void {
    // Get yesterday's date
    const now = this._dateUtilityService.startOfDay(new Date());
    const yesterday = this._dateUtilityService.endOfDay(new Date());

    // Use shareReplay to allow multiple subscribers without triggering multiple HTTP calls
    const trips$ = this.listVehicleTrips$(
      vehicleId,
      yesterday.toISOString(),
      now.toISOString()
    ).pipe(
      shareReplay(1),
      catchError((err) => {
        console.error('Error loading trips:', err);
        return of([]);
      })
    );

    trips$.subscribe();
  }
}
