import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap, map, catchError } from 'rxjs';
import {
  icon,
  IconOptions,
  Icon,
  Map as LMap,
  Marker,
  marker,
  latLng,
  latLngBounds,
} from 'leaflet';
import { CommonService } from '../api-services/common.service';

export interface OsrmResponse {
  code: string;
  routes: {
    distance: number;
    duration: number;
    geometry: {
      coordinates: [number, number][];
    };
  }[];
  waypoints: {
    distance: number;
    location: [number, number];
    name: string;
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class OsrmMapService {
  private osrmUrl = 'https://osrm.vectore.it/route/v1/driving';
  private speedLimitCache: Map<string, number> = new Map();

  constructor(
    private http: HttpClient,
    private _commonService: CommonService
  ) {}

  /**
   * Fetches route data from OSRM service
   * @param coordinates String of coordinates in format "long1,lat1;long2,lat2;..."
   * @returns Observable with route information
   */
  getRoute(coordinates: string): Observable<OsrmResponse> {
    const url = `${this.osrmUrl}/${coordinates}?overview=full&geometries=geojson`;
    return this.http.get<OsrmResponse>(url);
  }

  /**
   * Centers the map view on specified coordinates
   * @param map Leaflet map instance
   * @param latitude Latitude to center on
   * @param longitude Longitude to center on
   * @param zoom Zoom level (default: 13)
   */
  centerViewMap(
    map: LMap,
    latitude: number,
    longitude: number,
    zoom: number = 13
  ): void {
    if (map) {
      map.setView([latitude, longitude], zoom);
    }
  }

  /**
   * Adds a marker to the map
   * @param map Leaflet map instance
   * @param latitude Marker latitude
   * @param longitude Marker longitude
   * @param message Optional message to display in popup
   * @returns Leaflet Marker instance
   */
  setMarker(
    map: LMap,
    latitude: number,
    longitude: number,
    message?: string
  ): Marker {
    const defaultMark: Icon<IconOptions> = icon({
      iconUrl: '../../../assets/images/marker-map.svg',
      iconSize: [38, 38],
      iconAnchor: [19, 38],
      popupAnchor: [0, -38],
    });

    if (message) {
      const popupContent = `
        <button class="btn-gs-primary d-flex gap-2" tabindex="0" style="align-items: center;">
          <i class="bi bi-car-front-fill"></i> ${message}
        </button>
      `;
      return marker([latitude, longitude], { icon: defaultMark })
        .bindPopup(popupContent, { maxWidth: 300 })
        .addTo(map);
    }

    return marker([latitude, longitude], { icon: defaultMark }).addTo(map);
  }

  /**
   * Observable wrapper for getRoute with error handling
   * @param coordinates String of coordinates in format "long1,lat1;long2,lat2;..."
   * @returns Observable with route information or null if coordinates are invalid
   */
  loadRoute$(coordinates: string): Observable<OsrmResponse | null> {
    if (!coordinates) {
      return of(null);
    }

    return this.getRoute(coordinates).pipe(
      tap({
        error: (error) => {
          this._commonService.handleError(error);
        },
      })
    );
  }

  /**
   * Adjusts the map view to fit all route coordinates
   * @param map Leaflet map instance
   * @param routeCoordinates Array of [longitude, latitude] coordinates
   */
  fitRouteBounds(map: LMap, routeCoordinates: [number, number][]): void {
    if (map && routeCoordinates && routeCoordinates.length > 0) {
      const latLngs = routeCoordinates.map((coord) =>
        latLng(coord[1], coord[0])
      );
      const bounds = latLngBounds(latLngs);
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }

  /**
   * Get speed limit for a given position
   * @param latitude Coordinate latitude
   * @param longitude Coordinate longitude
   * @returns Observable with speed limit information
   */
  getSpeedLimit(latitude: number, longitude: number): Observable<number> {
    // Validate coordinates
    if (!latitude || !longitude || isNaN(latitude) || isNaN(longitude)) {
      console.error(
        'Invalid coordinates for speed limit lookup:',
        latitude,
        longitude
      );
      return of(50); // Default urban speed limit
    }

    // Generate cache key
    const cacheKey = `${latitude.toFixed(5)},${longitude.toFixed(5)}`;

    // Check cache first
    if (this.speedLimitCache.has(cacheKey)) {
      return of(this.speedLimitCache.get(cacheKey));
    }

    // Option 1: Use Overpass API (OpenStreetMap data)
    const overpassUrl =
      'https://maps.mail.ru/osm/tools/overpass/api/interpreter';
    const query = `
      [out:json];
      way(around:20,${latitude},${longitude})[highway];
      out body;
      >;
      out skel;
    `;

    return this.http.post(overpassUrl, query, { responseType: 'text' }).pipe(
      map((response: string) => {
        // Parse response to find maxspeed tag
        try {
          const data = JSON.parse(response);
          const ways = data.elements.filter((el: any) => el.type === 'way');

          // First try: find explicit maxspeed tag
          for (const way of ways) {
            if (way.tags?.maxspeed) {
              // Extract numeric value from maxspeed tag (e.g., "50 km/h" -> 50)
              const speedText = way.tags.maxspeed.toString();
              const speedMatch = speedText.match(/(\d+)/);
              if (speedMatch) {
                const speedValue = parseInt(speedMatch[1], 10);
                if (!isNaN(speedValue)) {
                  // Store in cache
                  this.speedLimitCache.set(cacheKey, speedValue);
                  return speedValue;
                }
              }
            }
          }

          // Second try: estimate based on road type
          for (const way of ways) {
            if (way.tags?.highway) {
              const estimatedLimit = this.getDefaultSpeedLimit(
                way.tags.highway
              );
              // Store in cache
              this.speedLimitCache.set(cacheKey, estimatedLimit);
              return estimatedLimit;
            }
          }

          // No suitable road found
          const defaultLimit = 50; // Urban default
          this.speedLimitCache.set(cacheKey, defaultLimit);
          return defaultLimit;
        } catch (e) {
          console.error('Error parsing speed limit data:', e);
          const defaultLimit = 50;
          this.speedLimitCache.set(cacheKey, defaultLimit);
          return defaultLimit;
        }
      }),
      catchError((error) => {
        console.error('Error fetching speed limit:', error);
        const defaultLimit = 50;
        this.speedLimitCache.set(cacheKey, defaultLimit);
        return of(defaultLimit);
      })
    );
  }

  /**
   * Get default speed limit based on road type
   * @param roadType OpenStreetMap road type
   * @returns Estimated speed limit in km/h
   */
  private getDefaultSpeedLimit(roadType: string): number {
    // Mappatura standard dei limiti di velocità per tipo di strada in Italia
    const speedLimits: Record<string, number> = {
      motorway: 130, // Autostrada
      motorway_link: 90, // Raccordo autostradale
      trunk: 110, // Superstrada
      trunk_link: 70, // Raccordo superstrada
      primary: 90, // Strada statale
      primary_link: 70, // Raccordo strada statale
      secondary: 90, // Strada provinciale
      secondary_link: 70, // Raccordo strada provinciale
      tertiary: 70, // Strada locale
      tertiary_link: 50, // Raccordo strada locale
      unclassified: 70, // Strada non classificata
      residential: 50, // Strada urbana
      living_street: 20, // Zona residenziale
      service: 30, // Strada di servizio
      pedestrian: 10, // Area pedonale
      track: 30, // Strada sterrata
      path: 20, // Sentiero
      default: 50, // Valore predefinito (urbano)
    };

    return speedLimits[roadType] || speedLimits['default'];
  }

  /**
   * Clear speed limit cache
   */
  clearSpeedLimitCache(): void {
    this.speedLimitCache.clear();
  }

  /**
   * Get the current size of the speed limit cache
   * @returns Number of cached speed limits
   */
  getSpeedLimitCacheSize(): number {
    return this.speedLimitCache.size;
  }
}
