import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { VECTORE_API_BASE_URL } from '../constant/app-config';
import { MappingModel } from '../models/user/users';
import { CommonService } from './common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TrackResponse } from '../models/track/track-response';
import { TrackExportFilter, TrackFilter } from '../models/track/track-filter';
import { TenantService } from './tenant.service';
import { TripResponse } from '../models/trips/trip-response';

@Injectable({
  providedIn: 'root',
})
export class TrackersService {
  private _vehicleImageCache = new Map<string, Observable<any>>();
  private _tenantId: string;

  constructor(
    private _tenantService: TenantService,
    private _http: HttpClient,
    private _commonService: CommonService,
    private _domSanitizer: DomSanitizer,
    private _translateService: TranslateService,
    private _toastrService: ToastrService
  ) {
    this._tenantId = this._tenantService.getTenantId();
  }

  listVehicleTracks$(
    tenantId: string,
    vehicleId: string,
    params?: TrackFilter
  ): Observable<TrackResponse> {
    return this._http.get<TrackResponse>(
      `${VECTORE_API_BASE_URL}/${tenantId}/trackers/${vehicleId}/tracks`,
      { params: this._commonService.buildParams(params) }
    );
  }

  listVehicleTrips$(
    tenantId: string,
    vehicleId: string,
    params?: TrackFilter
  ): Observable<TripResponse> {
    return this._http.get<TripResponse>(
      `${VECTORE_API_BASE_URL}/${tenantId}/trackers/${vehicleId}/trips`,
      { params: this._commonService.buildParams(params) }
    );
  }

  exportVehiclesTracks$(
    tenantId: string,
    vehicleId: string,
    exportData: MappingModel,
    params?: TrackExportFilter
  ): Observable<HttpResponse<Blob>> {
    return this._http
      .post(
        `${VECTORE_API_BASE_URL}/${tenantId}/trackers/${vehicleId}/export/tracks`,
        exportData,
        {
          observe: 'response',
          params: this._commonService.buildParams(params),
          responseType: 'blob',
        }
      )
      .pipe(
        tap({
          error: (error) => this._commonService.handleError(error),
        })
      );
  }
}
