import { Component, Input, OnInit, OnDestroy, ViewChild, ElementRef, NgZone, Output, EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { InfoService } from 'src/app/shared/api-services/info.service';
import { PdfViewerModule, PdfViewerComponent, PDFDocumentProxy } from 'ng2-pdf-viewer';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MeService } from 'src/app/shared/api-services/me.service';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { TooltipModule } from 'primeng/tooltip';
import { Me } from 'src/app/shared/models/me/me';

@Component({
  selector: 'privacy-policy-modal',
  imports: [
    CommonModule,
    TranslateModule,
    PdfViewerModule,
    TooltipModule
  ],
  template: `
    <header class="d-flex justify-content-between align-items-center p-4">
      <h5 class="m-0">{{ 'MODAL_PRIVACY_POLICY.TITLE' | translate }}</h5>
      @if (me?.acceptPrivacyPolicy) {
        <button type="button" class="btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()"></button>
      }
    </header>

    @if (isLoading) {
      <div class="d-flex align-items-center justify-content-center" style="height: 70vh;">
        <div class="spinner-border text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    } 
    <content class="d-flex flex-column gap-3 p-2" [style.opacity]="isHidden ? '0' : '1'" [style.height]="isHidden ? '0' : '75vh'">
      <pdf-viewer
        class="border rounded"
        #pdfViewer
        [src]="src"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="isMobile ? 1.5 : 0.8"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        (after-load-complete)="afterLoadComplete($event)"
        style="width: 100%; height: 75vh;">
      </pdf-viewer>

      <div class="text-danger text-center">
        <p>
          {{ 'MODAL_PRIVACY_POLICY.DESCRIPTION' | translate }}
        </p>
      </div>

      <div class="d-flex justify-content-center">
        <button class="btn-outline-gs-primary" (click)="downloadDocument()">
          <i class="bi bi-download"></i>
          {{ 'DOWNLOAD' | translate }}
        </button>
      </div>
    </content>
    


    <footer class="d-flex gap-2 align-items-center justify-content-center p-4">
      @if (!me?.acceptPrivacyPolicy) {
        <button type="button" class="btn-gs-secondary" (click)="accept(false)">{{'ACTIONS.NOT_ACCEPT' | translate}}</button>
        <button type="button" class="btn-gs-primary" 
        (click)="accept(true)" 
          [disabled]="isDisabledAccept" 
          [pTooltip]="'MODAL_PRIVACY_POLICY.TOOOLTIP_ACCEPT' | translate"
        >
          {{'ACTIONS.ACCEPT' | translate}}
        </button>
      }
      @else {
        <button type="button" class="btn-gs-primary" (click)="bsModalRef.hide()">{{'ACTIONS.CLOSE' | translate}}</button>
      }
    </footer>
  `,
  styleUrls: ['./privacy-policy-modal.component.scss']
})
export class PrivacyPolicyModalComponent implements OnInit, OnDestroy {
  @Input() documentId: string;
  @Input() me: Me;
  
  isHidden = true;
  isLoading = false;
  src: string;
  isDisabledAccept = true;

  isMobile = false;

  private _document: Blob;
  
  @ViewChild('pdfViewer') pdfViewer: PdfViewerComponent;
  totalPages: number;

  @Output() onAcceptPrivacyPolicy = new EventEmitter<boolean>();

  private _destroy$ = new Subject<void>();

  constructor(
    public bsModalRef: BsModalRef,
    private _infoService: InfoService,
    private ngZone: NgZone,
    private elementRef: ElementRef,
    private _breakpointObserver: BreakpointObserver
  ) { 
    this._breakpointObserver.observe([Breakpoints.Handset])
    .pipe(takeUntil(this._destroy$))
    .subscribe((state: BreakpointState) => {
      this.isMobile = state.matches;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    this._infoService.downloadDocument$(this.documentId)
    .pipe(takeUntil(this._destroy$))
    .subscribe({
      next: (response) => {
        this.src = URL.createObjectURL(response.body);
        this._document = response.body;
      },
      error: (error) => {
        console.error(error);
        this.isLoading = false;
      }
    });
  }

  afterLoadComplete(pdf: PDFDocumentProxy) {
    this.totalPages = pdf.numPages;
    this.isLoading = false;
    this.isHidden = false;
    
    setTimeout(() => {
      const pdfViewerContainer = this.elementRef.nativeElement.querySelector('.pdfViewer');
      if (pdfViewerContainer) {
        // Evento scroll standard per desktop
        pdfViewerContainer.addEventListener('scroll', () => {
          this.ngZone.run(() => {
            this.checkScroll(pdfViewerContainer);
          });
        });
        
        // Eventi touch per dispositivi mobili
        pdfViewerContainer.addEventListener('touchmove', () => {
          this.ngZone.run(() => {
            this.checkScroll(pdfViewerContainer);
          });
        });
        
        pdfViewerContainer.addEventListener('touchend', () => {
          this.ngZone.run(() => {
            this.checkScroll(pdfViewerContainer);
          });
        });
      }
    }, 100);
  }
  
  checkScroll(element: HTMLElement) {
    // Controlla se l'utente ha raggiunto la fine del documento
    const isAtBottom = 
      element.scrollTop + element.clientHeight >= element.scrollHeight - 10;
      
    if (isAtBottom) {
      this.isDisabledAccept = false;
    }
  }

  downloadDocument() {
    const url = window.URL.createObjectURL(this._document);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'vectore_privacy_policy.pdf';
    a.click();
  }
  
  accept(accept: boolean) {
    if (accept) {
      this.onAcceptPrivacyPolicy.emit(true);
      this.bsModalRef.hide();
    }
    else {
      this.onAcceptPrivacyPolicy.emit(false);
      this.bsModalRef.hide();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
